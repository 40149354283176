<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <strong>Task Image</strong>
          </CCardHeader>
         <CRow>
         <CCol md="8"> 

          <CCardBody>
            <CForm>
           
            
       
         
                  <!--  -->
                   <CRow>
                    <CCol md="3"></CCol>
                    <CCol md="9" align="center">
                      <CCard  style="margin-top: 20px">
                      
                        <CCardBody align="center">
                      <!-- <CButton color="danger"  style="float: right" @click="removeimage() ">
                        <CIcon   name="cilXCircle" description="Delete"  ></CIcon>
                         </CButton> -->
                          <CImg
                            v-if="task.imageId"
                            :src="imageUrl(task.imageId)"
                            height="180px"
                            alt="Responsive image"
                            :disabled="mode.viewMode"
                          > </CImg>
                        </CCardBody>
                      </CCard>
                    </CCol>
                  </CRow>
            </CForm>
            
          </CCardBody>
          </CCol>
          
          </CRow>
          
          <CCardFooter align="right">
        

            <CButton class="mr-2" type="Cancel" size="sm" color="danger"  @click="cancel()">
              <CIcon name="cil-ban" />Cancel
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
 import Vue from "vue";
 import datetime from 'vuejs-datetimepicker';
import {
  required,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "Task Image",
  data() {
    return {
      mode:{
        newMode:false,
        editMode:false,
        viewMode:false,
      },
     taskImageId:"",
     
     task:{
          taskImageId:"0",
    taskId:"0",
      jobTaskId:'0',
      imageId:'',
      },
   
      horizontal: { label: "col-3", input: "col-9" },
    
    };
 },


   created() {  
             
             if(this.$route.params.mode =='view'){
                   this.mode.viewMode=true;
                    this.jobTaskId = this.$route.params.jobTaskId;
                      this.taskImageId = this.$route.params.taskImageId;
                    this.getUser();
            }
            if (this.$route.params.mode == "edit") {
                this.mode.editMode = true;
                this.jobTaskId = this.$route.params.jobTaskId;
                 this.taskImageId = this.$route.params.taskImageId;
                 this.getUser();
    }
        },
         
  methods: {
     imageUrl(imageId) {
      console.log(imageId);
      return process.env.VUE_APP_API_HOST + "/emapp/file/" + imageId;
    },

    editMode(){
       this.mode.editMode=true;
        this.mode.viewMode=false;
    },
     cancel() {
  
             this.$router.push({ name:  "Job Task",  params: {jobTaskId: this.$route.params.jobTaskId, mode: 'view'} });
    },
  
    getUser() {
     this.apiGetSecure(process.env.VUE_APP_API_HOST +"/emapp/web/secure/get/civil/job/image/"+ this.taskImageId)
        .then((response) => response.json())
        .then( (data)  => (this.task = data) );
        
    },
   
   
  },
  mounted() {
   
  }
};
</script>
